export const safeInvertedDifference = (a: any, b: any): number => {
    const numA = parseFloat(a);
    const numB = parseFloat(b);

    return ((isNaN(numA) ? 0 : numA) - (isNaN(numB) ? 0 : numB)) * -1;
};

export const safeSum = (a: any, b: any): number => {
    const numA = parseFloat(a);
    const numB = parseFloat(b);

    return (isNaN(numA) ? 0 : numA) + (isNaN(numB) ? 0 : numB);
};

export const zeroIfNaN = (num: any): number => isNaN(num) ? 0 : num;

export const nullIfNaN = (num: any): number | null => isNaN(num) ? null : num;

export const compareNumberAsc = (num1: number, num2: number) => {
    if (num1 < num2) {
        return -1;
    } else if (num1 > num2) {
        return 1;
    } else {
        return 0;
    }
}
